<template>
  <div class="select-company">
    <h2 class="select-company__logo">
      <span class="select-company__logo-sm">
        <img
          src="../../assets/images/pc/m640/img_logo.png"
          class="img_g"
          alt="Helinox"
          style="height: 26px; margin-top: 3px"
        />
      </span>
      <span class="select-company__logo-intranet">
        {{ $t("message.intranet") }}
      </span>
    </h2>

    <Select
      class="select-company__selectbox"
      :selectClass="'selectClass'"
      :options="companyList"
      :selectValue.sync="selectCompanyId"
    />

    <button
      :disabled="!selectCompanyId"
      class="select-company__btn-move"
      @click="onClickCompanySelect"
    >
      {{ $t("message.btn_move") }}
    </button>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import LocalStorageService from "@/plugins/LocalStorageService";

import SvgIcon from "@/components/common/SvgIcon.vue";

import LogoIntranet from "@/assets/svg/LogoIntranet.vue";

import { Select } from "@/components/common/select";

export default {
  name: "LoginCompany",
  components: {
    SvgIcon,
    LogoIntranet,
    Select,
  },
  data() {
    return {
      accountId: "",
      companyList: [],
      selectCompanyId: "",
    };
  },

  created() {
    console.log("created", this.$route.params);
    const { accountId } = this.$route.params;
    if (!accountId) {
      this.$router.push({ name: "login" });
    } else {
      this.accountId = accountId;
      this.getCompanyList();
    }

    // /login/companycompany
  },
  methods: {
    async getCompanyList() {
      console.log(this.accountId);
      const result = await this.$Api.getCompanyInfo({ accountId: this.accountId });
      console.log("result :>> ", result);
      const { status, data, statusText } = result;
      if (status !== 200) {
        alert(statusText);
        this.$router.push({ name: "login" });
        return;
      }
      const convertData = data.map((item) => {
        return {
          value: item.companyId,
          name: item.companyName,
        };
      });
      this.companyList = [{ value: "", name: "법인선택" }, ...convertData];
    },
    onChangeCompany(companyId) {
      this.selectCompanyId = companyId;
    },
    async onClickCompanySelect() {
      const result = await this.$Api.companySelect({
        companyId: this.selectCompanyId,
        vueUrl: window.location.origin,
      });
      window.location.href = result.data;
    },
  },
};
</script>
<style lang="scss" scoped>
.select {
  &-company {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    &__logo {
      &-sm,
      &-intranet {
        display: inline-block;
        vertical-align: middle;
      }
      &-sm {
        --svg-width: 136px;
        --svg-fill: currentColor;
      }
      &-intranet {
        margin-left: 10px;
      }
    }
    &__selectbox {
      min-width: 300px;
      margin-top: 40px;
    }
    &__btn {
      &-move {
        display: block;
        min-width: 300px;
        height: 56px;
        margin-top: 20px;
        border-radius: 3px;
        background-color: #000;
        color: #ffffff;
        font-size: 18px;
        &:hover {
          background-color: #333;
        }
        &[disabled] {
          background-color: #cecece;
        }
      }
    }
  }
}
</style>
